<template>
  <!-- pagination -->
  <div class="d-flex justify-content-between flex-wrap">
    <div class="d-flex align-items-center mb-0 mt-1">
      <span class="text-nowrap ">
        Showing 1 to
      </span>
      <b-form-select
        v-model="pageLength"
        :options="['20','50','100']"
        class="mx-1"
        @input="(value)=>props.perPageChanged({currentPerPage:value})"
      />
      <span class="text-nowrap"> of {{ props.total }} entries </span>
    </div>
    <div>
      <b-pagination
        :value="1"
        :total-rows="props.total"
        :per-page="pageLength"
        first-number
        last-number
        align="right"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
        @input="(value)=>props.pageChanged({currentPage:value})"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>
  </div>
</template>

<script>

import {
  BPagination, BFormSelect,
} from 'bootstrap-vue'

export default {
  components: {
    BPagination,
    BFormSelect,
  },
  props: {
    pageLength: {
      type: Number,
      default: 0,
    },
    props: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
    }
  },
}
</script>
