<template>
  <!-- search input -->
  <div class="custom-search d-flex justify-content-end">
    <b-form-group>
      <div class="d-flex align-items-center">
        <label class="mr-1">Search</label>
        <b-form-input
          v-model="searchTerm"
          placeholder="Search"
          type="text"
          class="d-inline-block"
          @change="$emit('changeSearchTerm', searchTerm)"
        />
      </div>
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      searchTerm: '',
    }
  },
}
</script>
