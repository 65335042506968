<template>
  <!-- actions -->
  <span>
    <b-dropdown
      variant="link"
      toggle-class="text-decoration-none"
      no-caret
    >
      <template v-slot:button-content>
        <feather-icon
          icon="MoreVerticalIcon"
          size="16"
          class="text-body align-middle mr-25"
        />
      </template>
      <b-dropdown-item @click="$emit('actionEdit')">
        <feather-icon
          icon="Edit2Icon"
          class="mr-50"
        />
        <span>Edit</span>
      </b-dropdown-item>
      <b-dropdown-item @click="$emit('actionDelete')">
        <feather-icon
          icon="TrashIcon"
          class="mr-50"
        />
        <span>Delete</span>
      </b-dropdown-item>
    </b-dropdown>
  </span>
</template>

<script>
import {
  BDropdown, BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
    }
  },
}
</script>
